<template>
  <div 
    class="program"
  >
    <div 
      class="ads scrollable pa-3"
      :style="{ 'max-height': maxHeight }"
    >
      <div 
        v-for="(ad, i) in controller.value.ads"
        :key="`ad-${i}`"
        class="my-4 d-flex align-center"
      >
        <div 
          class="actions px-2"
        >
          <m-btn-long
            :icon="icons.remove"
            press-color="error"
            :disabled="loading||disabled"
            :tooltip="{ top: true, text: 'Remover Anúncio' }"
            :duration="2000"
            @press="remove(i)"
          />
        </div>
        <media-player
          :url="ad.media"
          :pause-on-hover="false"
          rounded
          class="elevation-2"
        />
        <div 
          class="reorder px-2 d-flex flex-column"
        >
          <v-btn
            :disabled="loading||disabled||i==0"
            icon
            @click="reorder(i, 'up')"
          >
            <v-icon>
              {{ icons.up }}
            </v-icon>
          </v-btn>
          <v-btn
            :disabled="loading||disabled||i==controller.value.ads.length-1"
            icon
            @click="reorder(i, 'down')"
          >
            <v-icon>
              {{ icons.down }}
            </v-icon>
          </v-btn>
        </div>
      </div>
      <span 
        v-show="isEmpty"
        class="text-subtitle-1 text--secondary"
      >
        Selecione e ordene os anúncios para criar a fila
      </span>
    </div>
    <v-menu
      v-model="controller.campaigns.menu"
      offset-x
      left
      :close-on-content-click="false"
      close-delay="250"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :loading="controller.campaigns.loading"
          fab
          absolute
          bottom
          left
          small
          class="btn-add"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ icons.add }}</v-icon> 
          <!-- Selecionar anúncio -->
        </v-btn>
      </template>
      <v-card
        :loading="controller.campaigns.loading"
        class="campaign-list pt-1"
      >
        <v-text-field
          v-model="controller.campaigns.search"
          label="Buscar campanha..."
          solo
          flat
          hide-details
          class="ma-1"
        />
        <v-divider class="mb-2" />
        <v-card-text
          class="scrollable"
        >
          <div
            v-for="campaign in campaignList"
            :key="`campaign-${campaign.id}`"
            class="campaign pb-2"
          >
            <span class="text-subtitle-1 py-3">
              {{ campaign.title }}
            </span>
            <media-player
              v-for="ad in campaign.ads"
              :key="`ad-thumb-${ad.id}`"
              :url="ad.media"
              :title="ad.title"
              :pause-on-hover="false"
              rounded
              class="my-2"
              @click="add(ad)"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-fab-transition>
      <v-btn
        v-show="!isEmpty&&changed"
        :loading="loading"
        fab
        absolute
        bottom
        right
        color="primary"
        class="btn-save"
        @click="save"
      >
        <v-icon>{{ icons.check }}</v-icon> 
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<style lang="scss">

.program {
  position: relative;
  min-height: 72px;
}

.campaign-list .scrollable {
  max-height: 50vh;
}

.program .btn-add {
  // position: absolute;
  // top: 50%;
  // right: 0;
  // transform: translateY(-50%);
}

</style>

<script>
  import {
    mdiArrowDown,
    mdiArrowUp,
    mdiCheck,
    mdiPlus,
    mdiClose
  } from '@mdi/js'
  import services from '@/services'

  export default {
    props: {
      value: {
        type: [Object, Number],
        default: () => null
      },
      start: {
        type: String,
        default: null
      },
      end: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxHeight: {
        type: String,
        default: 'auto'
      },
      user: {
        type: Object,
        default: () => null
      }
    },
    
    data: () => ({
      icons: {
        add: mdiPlus,
        up: mdiArrowUp,
        down: mdiArrowDown,
        check: mdiCheck,
        remove: mdiClose
      },
      controller: {
        value: {
          program_id: null,
          dt_start: null,
          dt_end: null,
          ads: []
        },
        items: {},
        campaigns: {
          search: null,
          items: {},
          menu: false,
          loading: false
        },
      },
      loading: false,
      updated: false,
    }),
    
    computed: {
      campaignList () {
        const campaigns = this.controller.campaigns.items;
        const query = this.controller.campaigns.search;
        const list = this.searchEngine(campaigns, query, false, ['title', 'ads.id']);
        return list;
      },

      isEmpty () {
        const ads = this.controller.value.ads;
        return _.size(ads)==0;
      },

      changed () {
        const before = this.value;
        const data = this.controller.value;
        return before==null||_.some(data, (value, v) => {
          return !_.isEqual(value, before[v]);
        });
      },
      
    },

    watch: {
      value: {
        immediate: true,
        deep: true,
        handler (value) {
          this.controller.value = !!value ? _.cloneDeep(value) : {
            program_id: null,
            dt_start: this.start,
            dt_end: this.end,
            ads: []
          };
        }
      }
      
    },

    methods: {
      ...services,

      add (ad) {
        this.controller.value.ads.push(_.clone(ad));
        this.controller.campaigns.menu = false;
      },

      remove (index) {
        const ads = this.controller.value.ads;
        ads.splice(index, 1);
      },

      reorder (index, target) {
        target = target=='up' ? index-- : target=='down' ? index++ : target;
        console.log('reorder', index, target);
        setTimeout(($, index, target) => {
          const ads = $.controller.value.ads;
          ads.splice(target, 0, ads.splice(index, 1)[0]);
        }, 200, this, index, target);
      },

      save () {
        const id = _.isNil(this.value) ? null : this.controller.value.program_id;
        const fields = _.clone(this.controller.value);
        if (!!id) {
          this.update(id, fields);
        }else{
          this.create(fields);
        }
      },

      onUpdate (value, save=true) {
        // save = _.isNil(this.value)||save||this.value.program_id!=value.program_id;
        this.$emit('change', value, save);
      },

      create (fields) {
        this.loading = true;
        this.updated = false;

        fields.ads = _.join(_.map(fields.ads, 'id'), ',');
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          username,
          token,
          fields
        };

        console.log('programs.create...', data);
        this.$api.COM
          .post('/flashmob/ads', data)
          .then(response => {
            console.log('programs.create => ', response);
            const id = response.data.program_id;
            this.$set(this.controller.items, id, response.data);
            this.onUpdate(response.data);
            this.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.$emit('alert', 'Sua sessão expirou...')
            }else{
              this.$emit('alert', 'Não foi possível criar a fila, tente novamente.');
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },

      update (id, fields) {
        this.loading = true;
        this.updated = false;

        fields.ads = _.join(_.map(fields.ads, 'id'), ',');
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          username,
          token,
          fields
        };

        console.log('programs.update...', data);
        this.$api.COM
          .put('/flashmob/ads/'+id, data)
          .then(response => {
            console.log('programs.update => ', response);
            this.$set(this.controller.items, id, response.data);
            this.onUpdate(response.data);
            this.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.$emit('alert', 'Sua sessão expirou...')
            }else{
              this.$emit('alert', 'Não foi possível salvar a fila, tente novamente.');
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },

      get () {
        this.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          username,
          token,
        };

        console.log('programs.get...', data);
        this.$api.COM
          .get('/flashmob/ads', { params: data })
          .then(response => {
            console.log('programs.get => ', response);
            this.controller.items = Object.assign({}, _.keyBy(response.data, 'id'));
            this.updated = true;
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.$emit('alert', 'Sua sessão expirou...')
            }else{
              this.$emit('alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              this.loader = setTimeout(($) => {
                this.loader = null;
                $.get();
              }, 7000, this);
            }
          })
          .finally(() => {
            this.loading = false;
            this.campaigns();
          });
      },

      campaigns () {
        this.controller.campaigns.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          username,
          token,
        };

        console.log('campaigns.get...', data);
        this.$api.COM
          .get('/flashmob/campaigns', { params: data })
          .then(response => {
            console.log('campaigns.get => ', response);
            this.controller.campaigns.items = Object.assign({}, _.keyBy(_.map(response.data, item => {
              const { 
                id_campanha: id,
                nm_campanha: title,
                ads,
              } = item;
              return {
                id, 
                title, 
                ads: _.map(ads, ad => {
                  return { id: ad.id_anuncio, id_media: ad.id_midia, media: ad.media }
                })
              }
            }), 'id'));
          })
          .catch(error => {
            if (error.response.status==401) {
              this.getout();
              this.$emit('alert', 'Sua sessão expirou...')
            }else{
              this.$emit('alert', 'Aguardando resposta do sistema... ⏳');
              // try again in 7s
              this.loader = setTimeout(($) => {
                this.loader = null;
                $.campaigns();
              }, 7000, this);
            }
          })
          .finally(() => {
            this.controller.campaigns.loading = false;
          });
      },
    },

    mounted () {
      this.campaigns();
    },

    components: {
      MediaPlayer: () => import('@/components/MediaPlayer'),
      MBtnLong: () => import('@/components/mBtnLong'),
    }
  }
</script>